
export function assertEquals(expected, value) {
    
    if (expected !== value) {
        throw('Failed assertEquals.'
                + '\tExpected: ' + expected + ''
                + '\tActual: ' + value
            ); 
    } else {
        console.info('Passed assertEquals.'
                + '\tExpected: ' + expected + ''
                + '\tActual: ' + value
            ); 
    }

}

export function trimStartDot(path) {
    path = path.replace(/^(\.)/, '');
    return path;
}
export function trimEndDot(path) {
    path = path.replace(/(\.)$/, '');
    return path;
}

export function trimDot(path) {
    return trimStartDot(trimEndDot(path));
}

export function randomString(length = 6) {
    return Math.random().toString(16).substring(2, length);
};


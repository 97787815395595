import Node          from "./Node";
import { useState, useEffect, useRef }     from "react";

export default function DragDiv(props) {

    const outerElemRef = useRef();
    const innerElemRef = useRef();

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [translateX, setTranslateX] = useState(0);
    const [translateY, setTranslateY] = useState(0);
    const [currentTranslateX, setCurrentTranslateX] = useState(0);
    const [currentTranslateY, setCurrentTranslateY] = useState(0);
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [clickEnabled, setClickEnabled] = useState(true);

    function onMouseDown(e) {

        setIsMouseDown(true);

        setStartX(e.pageX);
        setStartY(e.pageY);

        setCurrentTranslateX(translateX);
        setCurrentTranslateY(translateY);

    }

    function stopDragging() {
        setIsMouseDown(false);
        setClickEnabled(true);
    }

    function onMouseMove(e) {

        if (!isMouseDown) {
            return false;
        }

        let scrolledX = startX - e.pageX;
        let scrolledY = startY - e.pageY;

        setTranslateX(currentTranslateX - (scrolledX * (1 / props.scale)));
        setTranslateY(currentTranslateY - (scrolledY * (1 / props.scale)));

        setClickEnabled(false);

    }

    function onWheel(e) {
        setTranslateX(translateX => {
            return translateX - (e.deltaX * (1 / props.scale));
        });

        setTranslateY(translateY => {
            return translateY - (e.deltaY * (1 / props.scale));
        });
    }

    function onClickCapture(e) {
        if (!clickEnabled) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    let outerStyle = Object.assign({}, props.style, {
        cursor: isMouseDown ? 'grabbing' : 'default',
    });

    let innerStyle = {
        width: '100%',
        height: '100%',
        transform: 'translate(' + (translateX*props.scale) + 'px, ' + (translateY*props.scale) + 'px)',
        // transform: 'matrix(1, 0, 0, 1, ' + (translateX*props.scale) + ', ' + (translateY*props.scale) + ')',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <div {...props} style={outerStyle} ref={outerElemRef} 
            onMouseDown={onMouseDown} onMouseUp={stopDragging} onMouseLeave={stopDragging} onMouseMove={onMouseMove} onClickCapture={onClickCapture}
            onWheel={onWheel}
            >
            <div style={innerStyle} ref={innerElemRef}>
                {props.children}
            </div>
        </div>
    );
}

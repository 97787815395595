import Tree from "./Tree";
import { randomString } from "./Utils";

let rootNode = {
    title: 'root',
    children: [
        {
            title: 'jamón',
            original_url: 'https://kodens.es/',
            url_path: '/jamón',
            screenshot_status: 'done',
            screenshot_url: 'https://s3.eu-central-003.backblazeb2.com/WebsiteMapper/2022/09/175ce412-30d9-4191-a2d3-31373493a807/https-kodens-es-trabajo-voltadol.png',
            hash: randomString(),
            children: [
                { title: 'jorge', url_path:'/jorge', hash: randomString(), children: [] },
                { title: 'jack', url_path:'/jack',   hash: randomString(), children: [] },
                { title: 'james', url_path:'/james', hash: randomString(), children: [] },
            ]
        },
        
        {
            title: 'Quisque ut dolor gravida, placerat libero vel, euismod. Quisque ut dolor gravida, placerat libero vel, euismod.',
            url_path: '/quisque',
            original_url: 'https://kodens.es/trabajos',
            screenshot_status: 'failed',
            screenshot_url: null,
            hash: randomString(),
            children: [
                { title: 'jorge', original_url: 'https://kodens.es/contacto', url_path:'/jorge', hash: randomString(), children: [] },
                { title: 'jack',  original_url: 'https://kodens.es/trabajo-gruporestalia', url_path:'/jack',   hash: randomString(), children: [] },
                { title: 'james', original_url: 'https://kodens.es/nosotros', url_path:'/james', hash: randomString(), children: [] },
            ]
        },
       
        {
            title: 'jamón 2',
            url_path: '/jamón-2',
            original_url: 'https://kodens.es/nosotros',
            hash: randomString(),
            children: [
                { title: 'jorge', url_path:'/jorge', hash: randomString(), children: [] },
                { title: 'jack', url_path:'/jack',   hash: randomString(), children: [] },
                { title: 'james', url_path:'/james', hash: randomString(), children: [] },
            ]
        },
        
       
        {
            title: 'Morrococo',
            url_path: '/morrococo',
            hash: randomString(),
            children: [
                {
                    title: 'centollo',
                    url_path: '/centollo',
                    hash: randomString(),
                    children: [
                        {
                            title: 'churrasco',
                            url_path: '/churrasco',
                            hash: randomString(),
                            children: [ ]
                        },
                    ]
                },
            ]
        },
    ]
};


function App() {

    function onChange(newNodes) {
        console.log('onChange', newNodes);
    }

    return (
        <div className="App">
            <Tree rootNode={rootNode} onChange={onChange} />
        </div>
    );
}

export default App;

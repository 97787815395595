import { createElement } from 'react'
import { render } from 'react-dom'
import Tree from './Tree'

export default class KDComponents {

    static createTree(elem, props) {
        render(createElement(Tree, props), elem);
    }

}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import KDComponents from './KDComponents';

const rootElm = document.getElementById('root');

if (rootElm) {
    const root = ReactDOM.createRoot(rootElm);
    
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

window.KDComponents = KDComponents;

String.prototype.hashCode = function() {
    let hash = 0;
    for (let i = 0; i < this.length; i++) {
        let character = this.charCodeAt(i);
        hash = ((hash<<5)-hash)+character;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}


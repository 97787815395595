import { assertEquals, trimStartDot, trimEndDot, randomString } from "./Utils";

export class TreeManipulation {

    static addNode(nodes, parentPath) {
        let newNodes = recursiveDeepCopy(nodes);

        let parentNode = getNode(newNodes, parentPath);

        parentNode.children = parentNode.children || [];

        let newNode = {
            title: 'New node',
            hash: randomString(6),
            url_path: '/path',
            children: []
        };

        parentNode.children.push(newNode);

        return newNodes;
    }

    static deleteNode(nodes, nodePath) {
        let newNodes = recursiveDeepCopy(nodes);
        removeNodeFromParent(newNodes, nodePath);
        return newNodes;
    }

    static moveNode(nodes, originNodePath, toParentPath, atIndex = null) {
        let newNodes = recursiveDeepCopy(nodes);

        let newParentNode = getNode(newNodes, toParentPath);
        let oldParentNode = getParentNode(newNodes, originNodePath);

        if (newParentNode === oldParentNode && atIndex === null) { // Son iguales, no se mueven
            return newNodes;
        }

        let movedNode = removeNodeFromParent(newNodes, originNodePath);

        newParentNode.children = newParentNode.children || [];
        
        if (atIndex !== null) {
            newParentNode.children.splice(atIndex, 0, movedNode);
        } else {
            newParentNode.children.push(movedNode);
        }

        return newNodes;
    }

    static changeDate(nodes, nodePath, field, newValue) {
        let newNodes = recursiveDeepCopy(nodes);
        let node = getNode(newNodes, nodePath);
        node[field] = newValue;
        return newNodes;
    }

}

export function pathToArray(path) {
    path = trimStartDot(path);
    let keys = path.split('.').filter(str => str).map(str => parseInt(str, 10));
    return keys;
}

export function getNode(rootNode, path) {
    let keys = pathToArray(path);

    keys.shift();
    let node = rootNode;

    keys.forEach(key => {
        node = node.children[key];
    });

    return node;
}

export function getParentPath(path) {
    let keys = pathToArray(path);
    keys.pop();
    return keys.join('.');
}

export function getLastKey(path) {
    let keys = pathToArray(path);
    return keys.pop();
}

export function getParentNode(rootNode, path) {
    let parentPath = getParentPath(path);
    let parent = getNode(rootNode, parentPath);
    return parent;
}

export function removeNodeFromParent(rootNode, path) {
    let childKey = getLastKey(path);
    let parent = getParentNode(rootNode, path);
    let node = parent.children[childKey];
    parent.children.splice(childKey, 1);
    return node;
}

export function rekeyNodes(nodes, basePath) {
    nodes.forEach((node, i) => {
        node.path = basePath + '.' + i;
    });
}

export function isAncestorOf(path, potentialAncestorPath) {
    path = trimStartDot(path);
    potentialAncestorPath = trimStartDot(potentialAncestorPath);
    potentialAncestorPath = trimEndDot(potentialAncestorPath);

    if (path === potentialAncestorPath) {
        return false;
    }

    if (path.startsWith(potentialAncestorPath)) {
        return true;
    }

    return false;
}

function recursiveDeepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

// assertEquals(true, isAncestorOf('0.0', '0'));
// assertEquals(true, isAncestorOf('0.1', '0'));
// assertEquals(false, isAncestorOf('1.1', '0'));
// assertEquals(true, isAncestorOf('0', ''));
import Node          from "./Node";
import { useState, useEffect, useRef }     from "react";
import { TreeManipulation }  from "./TreeManipulation";
import DragDiv from "./DragDiv";

function Tree(props) {

    let rootNode = props.rootNode || props.rootnode;
    if (typeof rootNode === 'string') {
        rootNode = JSON.parse(rootNode);
    }

    const containerElm = useRef();

    const [nodes, setNodes] = useState(rootNode);
    const [scale, setScale] = useState(1);

    useEffect(() => {
        containerElm.current.style.opacity = 1;
        props.onLoad && props.onLoad();
    }, []);

    function onMoveNode(originNodePath, toParentPath, atIndex = null) {
        setNodes(nodes => {
            let newNodes = TreeManipulation.moveNode(nodes, originNodePath, toParentPath, atIndex);
            props.onChange && props.onChange(newNodes);
            return newNodes;
        });
    }

    function onAddNode(parentPath) {
        setNodes(nodes => {
            let newNodes = TreeManipulation.addNode(nodes, parentPath);
            props.onChange && props.onChange(newNodes);
            return newNodes;
        });
    }

    function onDeleteNode(nodePath) {
        setNodes(nodes => {
            let newNodes = TreeManipulation.deleteNode(nodes, nodePath);
            props.onChange && props.onChange(newNodes);
            return newNodes;
        });
    }

    function onChangeData(nodePath, field, newValue) {
        setNodes(nodes => {
            let newNodes = TreeManipulation.changeDate(nodes, nodePath, field, newValue);
            props.onChange && props.onChange(newNodes);
            return newNodes;
        });
    }

    function onZoomInClick() {
        if (scale >= 2) {
            return false;
        }
        setScale(scale + 0.05);
    }

    function onZoomOutClick() {
       if (scale <= 0.10) {
            return false;
        }
        setScale(scale - 0.05);
    }

    let treeStyle = {
        transform: 'scale(' + scale + ')'
    }

    return (
        <div className="TreeContainer" ref={containerElm} style={{ opacity: 0, transition: 'opacity 1s' }}>
        
            <DragDiv className="TreeWrapper" scale={scale}>
                <div className="Tree" style={treeStyle}>
                    <Node 
                        data={nodes} 
                        isRoot={true} 
                        path={'0'} 
                        onMoveNode={onMoveNode} 
                        onAddNode={onAddNode} 
                        onDeleteNode={onDeleteNode} 
                        onChangeData={onChangeData}
                        /> 
                </div>
            </DragDiv>


            <div className="Controls">
                <button onClick={onZoomInClick}>
                    <i className="fa-solid fa-plus"></i>
                </button>
                <button onClick={onZoomOutClick}>
                    <i className="fa-solid fa-minus"></i>
                </button>
            </div>
        </div>
    );
}

export default Tree;

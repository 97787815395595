import { pathToArray, getParentPath, getLastKey, isAncestorOf }  from "./TreeManipulation";
import { useState }     from "react";

const DEFAULT_TEXTAREA_HEIGHT = 16;
const MAX_TEXTAREA_HEIGHT = 48;

export default function Node(props) {

    const [showChildren, setShowChildren] = useState(true);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const [isDraggingOverBefore, setIsDraggingOverBefore] = useState(false);
    const [isDraggingOverAfter,  setIsDraggingOverAfter] = useState(false);
    const [titleHeight, setTitleHeight] = useState(DEFAULT_TEXTAREA_HEIGHT);

   

    function onMouseDown(ev) {
        ev.stopPropagation(); // Evitamos interferir con el drag scroll de DragDiv
    }

    function onDragStart(ev) {
        ev.dataTransfer.setData('text/plain', props.path);
        ev.dataTransfer.effectAllowed = 'move';
    }

    function onDragOver(ev) {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = 'move';
    }

    function onDragEnter(side) {
        if (side === 'before') {
            setIsDraggingOverBefore(true);
        } else if (side === 'after') {
            setIsDraggingOverAfter(true);
        } else {
            setIsDraggingOver(true);
        }
    }

    function onDragLeave(side, ev) {
        if (ev.currentTarget.contains(ev.relatedTarget)) {
            return;
        }

        if (side === 'before') {
            setIsDraggingOverBefore(false);
        } else if (side === 'after') {
            setIsDraggingOverAfter(false);
        } else {
            setIsDraggingOver(false);
        }

    }

    function onDrop(ev) {
        let movedNodePath = ev.dataTransfer.getData('text/plain');
        // ev.target.classList.remove('dragging-over');

        if (props.path === movedNodePath || isAncestorOf(props.path, movedNodePath)) {
            return;
        }
        
        setIsDraggingOver(false);
        props.onMoveNode && props.onMoveNode(movedNodePath, props.path);
    }

    function onSideDrop(side, ev) {
        let movedNodePath = ev.dataTransfer.getData('text/plain');
        let thisParentPath = getParentPath(props.path);
        let thisKey = parseInt(getLastKey(props.path), 10);

        let atIndex = side === 'before' 
                            ? thisKey
                            : thisKey + 1;

        setIsDraggingOverBefore(false);
        setIsDraggingOverAfter(false);
        setIsDraggingOver(false);

        props.onMoveNode && props.onMoveNode(movedNodePath, thisParentPath, atIndex);

    }

    function toggleChildren() {
        setShowChildren(!showChildren);
    }

    function onAddClick() {
        props.onAddNode && props.onAddNode(props.path);
    }

    function onDeleteClick() {
        props.onDeleteNode && props.onDeleteNode(props.path);
    }

    function onTitleChange(ev) {
        let scrollHeight = ev.target.scrollHeight;
        let height = scrollHeight > MAX_TEXTAREA_HEIGHT 
                        ? MAX_TEXTAREA_HEIGHT 
                        : scrollHeight;
        setTitleHeight(height);

        props.onChangeData && props.onChangeData(props.path, 'title', ev.target.value);
    }

    function onUrlChange(ev) {
        props.onChangeData && props.onChangeData(props.path, 'url_path', ev.target.value);
    }

    function onTitleBlur(ev) {
        ev.target.scrollTop = 0;
    }

    function onTitleKeydown(ev) {
        if (ev.keyCode == 13 && !ev.shiftKey) {
            ev.preventDefault();
            return false;
        }
    }

    let hasChildren = props.data.children && props.data.children.length > 0;

    let classes = ['Node'];

         if (props.isUnique) { classes.push('Node--isUnique'); } 
    else if (props.isFirst)  { classes.push('Node--isFirst'); } 
    else if (props.isLast)   { classes.push('Node--isLast'); }

    return (
        <div className={classes.join(' ')} >

            {props.hasParent && <div className="Node__parentLine" />}
            {props.hasParent && <div className="Node__branchLine" />}

            <div className="Node__header">
                {!props.isRoot && 
                    <div className={'Node__before ' + (isDraggingOverBefore && 'dragging-over')} onDragOver={onDragOver} onDrop={onSideDrop.bind(this, 'before')} onDragEnter={onDragEnter.bind(this, 'before')} onDragLeave={onDragLeave.bind(this, 'before')}
                    onMouseDown={onMouseDown}
                    ></div>
                }
                <div className={'Node__box ' + (isDraggingOver && 'dragging-over')} draggable={!props.isRoot} 
                    onDragStart={onDragStart} onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter} onDragLeave={onDragLeave.bind(this, 'main')}
                    onMouseDown={onMouseDown}
                    
                    >
                    <div className="Node__path">
                        {props.path}
                    </div>

                    <div className="Node__url" title={props.data.url_path}>
                        <input type="text" defaultValue={props.data.url_path} onChange={onUrlChange} />
                    </div>
                    
                    <div className="Node__title">
                        <textarea defaultValue={props.data.title} onChange={onTitleChange} onBlur={onTitleBlur} onKeyDown={onTitleKeydown} style={{height: titleHeight}}></textarea>
                    </div>

                    {(() => {

                        let screenshotUrl = props.data.screenshot_thumb_url || props.data.screenshot_url;

                        if (props.data.screenshot_status === 'done' && screenshotUrl) {
                            return <a href={props.data.screenshot_url} className="Node__image magnific">
                                <img src={screenshotUrl} loading="lazy" />
                            </a>
                        } else if (props.data.screenshot_status === 'failed') {
                            return <div className="Node__imagepending">
                                ❌<br/>
                                Screenshot failed.
                            </div>
                        } else if (props.data.screenshot_status === 'pending') {
                            return <div className="Node__imagepending">
                                ⏳<br/>
                                Pending screenshot...
                            </div>
                        }
                    })()}

                    <div className="Node__actions">
                        <button onClick={onAddClick}>
                            <i className="fa-solid fa-plus"></i>
                        </button>
                        {hasChildren && 
                            <button onClick={toggleChildren} className={showChildren ? '' : 'closed'}>
                                {showChildren 
                                    ? <i className="fa-solid fa-chevron-down"></i>
                                    : <span>
                                        {props.data.children.length}
                                      </span>
                                }
                            </button>
                        }
                        {!props.isRoot && 
                            <button onClick={onDeleteClick}>
                                <i className="fa-regular fa-trash-can"></i>
                            </button>
                        }
                    </div>
                    
                </div>
                {!props.isRoot && 
                    <div className={'Node__after ' + (isDraggingOverAfter && 'dragging-over')} onDragOver={onDragOver} onDrop={onSideDrop.bind(this, 'after')} onDragEnter={onDragEnter.bind(this, 'after')} onDragLeave={onDragLeave.bind(this, 'after')}
                    onMouseDown={onMouseDown}
                    ></div>
                }
                {showChildren && hasChildren && <div className="Node__childrenLine" />}
            </div>

            {showChildren && hasChildren &&
                <div className="Node__children">
                    {props.data && props.data.children.map((child, k) => {
                        
                        let isFirst  = k === 0;
                        let isLast   = k === props.data.children.length - 1;
                        let isUnique = props.data.children.length === 1;

                        return <Node 
                                    key={child.hash}
                                    path={props.path + '.' + k}
                                    hasParent={true} 
                                    isUnique={isUnique} 
                                    isFirst={isFirst} 
                                    isLast={isLast} 
                                    onMoveNode={props.onMoveNode}
                                    onDeleteNode={props.onDeleteNode}
                                    onAddNode={props.onAddNode}
                                    onChangeData={props.onChangeData}
                                    data={child} 
                                    />
                    })}
                </div>
            }
        </div>
    );
}